// external
import React, { useState, useEffect, useRef } from "react";
import { createRoot } from "react-dom/client";
import { MarkerClusterer, SuperClusterAlgorithm } from "@googlemaps/markerclusterer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faCar,
  faFaceSmile,
  faGlobe,
  faHouseFlag,
  faInfoCircle,
  faKey,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";

// internal
import STYLED_MAP_TYPE from "./StyledMapType.js";
import { offsetCenter } from "../functions/geoCalculations.js";

const MapOfFarms = ({ center, zoom, farms, searchFacets, originLatLng, isInitialLoad }) => {
  const ref = useRef(null);
  const [map, setMap] = useState();

  useEffect(() => {
    // Intialise map
    if (!map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          mapId: "df4f2b09a94f9aa8", // Configure map here: https://console.cloud.google.com/google/maps-apis/studio/maps/df4f2b09a94f9aa8?project=alpaca-maps
          mapTypeControl: false,
          center: center,
          zoom: zoom,
        })
      );
    }

    // Update map
    if (map) {
      // Ref: Google map style
      // https://developers.google.com/maps/documentation/javascript/examples/maptype-styled-simple
      // Create a new StyledMapType object, passing it an array of styles,
      // and the name to be displayed on the map type control.
      const styledMapType = new window.google.maps.StyledMapType(STYLED_MAP_TYPE, { name: "Alpaca Styled Map" });

      // Associate the styled map with the MapTypeId and set it to display.
      map.mapTypes.set("styled_map", styledMapType);
      map.setMapTypeId("styled_map");
    }
  }, [map, center, zoom]);

  // Initialise map bounds
  useEffect(() => {
    let bounds = null;

    if (isInitialLoad) {
      // Calculate map bounds
      // Used this tool to find rough Norwegian bounds to hardcode bounds http://bboxfinder.com/#55.447711,0.085144,72.485198,33.835144
      bounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng({ lat: 57.950392, lng: 4.606705 }), // South West
        new window.google.maps.LatLng({ lat: 71.476433, lng: 31.501236 }) // North East
      );
    }

    if (isInitialLoad && map) {
      console.log("// Initialise map bounds // isInitialLoad: ", isInitialLoad);
      map.fitBounds(bounds);
    }
  }, [map, isInitialLoad]);

  // Update map bounds
  useEffect(() => {
    if (!originLatLng) return; // If start location is selected...

    let bounds = new window.google.maps.LatLngBounds();

    if (!isInitialLoad && map && farms) {
      // ... and if not the initial default load update  bounds to auto zoom to nearest
      // Calculating showing bounds of all farms results in dead grey space around smaller vertical mobile view mode
      // since logically the whole world does not stretch vertically 100vh to fit, eg if showing Norway and Australia
      farms.forEach((farm) => bounds.extend(new window.google.maps.LatLng({ lat: farm.lat, lng: farm.lng })));

      console.log("// Update map bounds // originLatLng: ", originLatLng);
      map.fitBounds(bounds);
    }
  }, [map, farms, isInitialLoad, originLatLng]);

  return (
    <>
      <section id="google-map">
        <div ref={ref} id="map" />

        {map && farms && <Farms map={map} farms={farms} originLatLng={originLatLng} />}

        {map && (searchFacets.currentLocationToggle || searchFacets.selectLocationToggle) && originLatLng && (
          <OriginMarker position={originLatLng} map={map} />
        )}
      </section>
    </>
  );
};

const Farms = ({ map, farms }) => {
  const [markerClusterer, setMarkerClusterer] = useState(null);
  const currentFarm = useRef({ id: 0, marker: null });
  const previousFarm = useRef({ id: 0, marker: null });

  // add marker clusterer on map
  useEffect(() => {
    if (markerClusterer) {
      return;
    }

    setMarkerClusterer(
      new MarkerClusterer({
        map,
        algorithm: new SuperClusterAlgorithm({ radius: 200 }),
      })
    );
  }, [markerClusterer, map]);

  return (
    <>
      {farms.map((farm) => {
        const farmInfoLink = `/farm/${farm?.id}`;
        const farmDirections = farm?.location?.google?.directions_url_href
          ? farm?.location?.google?.directions_url_href
          : null;

        return (
          <FarmMarker
            key={farm.id}
            farm={farm}
            map={map}
            markerClusterer={markerClusterer}
            currentFarm={currentFarm}
            previousFarm={previousFarm}>
            <div
              id={`farm-marker-id-${farm.id}`}
              role="button"
              data-testid="farm-marker"
              className={`farm-marker ${farm.private === true ? "private" : "public"}`}>
              <div className="summary">
                <div className="icon">
                  <FontAwesomeIcon icon={farm.private === true ? faKey : faHouseFlag} />
                </div>
                <div className="count">{farm.count?.alpacas?.status?.active} 🦙</div>
              </div>
              <div
                className="more-info hidden"
                data-testid={`farm-marker-info-id-${farm.id}`}
                id={`farm-marker-info-id-${farm.id}`}>
                <div className="name">
                  <h2 data-testid={`farm-marker-info-id-${farm.id}-name`}>{`${farm.name}`}</h2>
                </div>
                <div className="city">
                  <address>
                    <FontAwesomeIcon icon={faLocationDot} /> {`${farm.city}`}
                  </address>
                </div>
                <div className="address">
                  <address>{`${farm.location.google.formatted_address}`}</address>
                </div>

                <a
                  data-testid="farm-marker-info-link"
                  href={farmInfoLink}
                  onTouchStart={(e) => {
                    e.stopPropagation();
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}>
                  <div className="farm-marker-link">
                    <address>
                      <span className="icon">
                        <FontAwesomeIcon icon={faInfoCircle} />
                      </span>
                      <span className="text">Farm info</span>
                    </address>
                  </div>
                </a>

                {farm?.url && (
                  <a
                    data-testid="farm-marker-webpage-link"
                    href={farm?.url?.full}
                    target="_blank"
                    rel="noreferrer"
                    title={farm?.url?.pretty}
                    onTouchStart={(e) => {
                      e.stopPropagation();
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}>
                    <div className="farm-marker-link">
                      <address>
                        <span className="icon">
                          <FontAwesomeIcon icon={faGlobe} />
                        </span>
                        <span className="text">Webpage</span>
                        <span className="icon link-arrow">
                          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                        </span>
                      </address>
                    </div>
                  </a>
                )}

                {farmDirections && (
                  <a
                    data-testid="farm-marker-directions-link"
                    href={farmDirections}
                    target="_blank"
                    rel="noreferrer"
                    title="Google directions"
                    onTouchStart={(e) => {
                      e.stopPropagation();
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}>
                    <div className="farm-marker-link">
                      <address>
                        <span className="icon">
                          <FontAwesomeIcon icon={faCar} />
                        </span>
                        <span className="text">Directions</span>
                        <span className="icon link-arrow">
                          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                        </span>
                      </address>
                    </div>
                  </a>
                )}
              </div>
            </div>
          </FarmMarker>
        );
      })}
    </>
  );
};

const FarmMarker = ({ map, currentFarm, previousFarm, markerClusterer, children, farm }) => {
  const markerRef = useRef();
  const rootRef = useRef();
  const hide = useRef(false);
  const farmMarker_zIndex_above = 101;
  const farmMarker_zIndex_default = 100;

  // Initialise marker
  useEffect(() => {
    if (!rootRef.current) {
      const container = document.createElement("div");
      rootRef.current = createRoot(container);

      markerRef.current = new window.google.maps.marker.AdvancedMarkerView({
        content: container,
        zIndex: farmMarker_zIndex_default,
        gmpClickable: true,
      });
    }
    return () => (markerRef.current.map = null);
  }, []);

  // Add listener, render marker when updated
  useEffect(() => {
    const setSelectedFarm = () => {
      // Do not use state to set selected farm as will re-loop and re-render all markers making it slow
      // Instead use plain JS to manipulate marker, Info area content

      const farmDirections = farm?.location?.google?.directions_url_href;
      const farmPosition = { lat: farm?.location?.coordinates[1], lng: farm?.location?.coordinates[0] };

      if (previousFarm.current.id !== 0) {
        // Update previously selected
        try {
          previousFarm.current = { id: previousFarm.current.id, marker: previousFarm.current.marker };
          previousFarm.current.marker.zIndex = farmMarker_zIndex_default;

          const prevElem = window.document.getElementById(`farm-marker-id-${previousFarm.current.id}`);
          prevElem.classList.remove("highlight");

          const prevElemInfo = window.document.getElementById(`farm-marker-info-id-${previousFarm.current.id}`);
          prevElemInfo.classList.add("hidden");
        } catch (error) {
          // "Element no longer visible on map, eg if farm toggled off"
          // TODO prevent this with logic perhaps, eg in <Farms> set this and use
          // const previousFarmInSelection = farms.map((farm) => farm.id).includes(previousFarm.current);
        }
      }

      currentFarm.current = { id: farm.id, marker: markerRef.current };

      if (currentFarm.current.id !== 0) {
        // Update currently selected

        try {
          const elem = window.document.getElementById(`farm-marker-id-${currentFarm.current.id}`);
          elem.classList.add("highlight");

          const elemInfo = window.document.getElementById(`farm-marker-info-id-${currentFarm.current.id}`);
          elemInfo.classList.remove("hidden");

          const elemInfoArea = window.document.getElementById(`address-selected-farm`);

          const address = `<p> ${farm?.location?.google?.formatted_address}`;
          elemInfoArea.innerHTML = `${farm?.name}<br>${farm.count?.alpacas?.status?.active} 🦙`;

          elemInfoArea.innerHTML += `<p><img src="fontawesome/location-dot.svg" alt="location-pin" class="icon"/>${farm?.city}<p>${address}`;

          elemInfoArea.innerHTML += `<p>
            ${
              farm?.public === true
                ? '<img src="fontawesome/house-flag.svg" alt="house-flag" class="icon"/>Public farm'
                : '<img src="fontawesome/key.svg" alt="key" class="icon"/>Private farm'
            }
            </p>`;

          elemInfoArea.innerHTML += `<p>
            <img src="fontawesome/circle-info.svg" alt="info" class="icon"/>
            <a
              data-testid="farm-info-link"
              href="/farm/${farm?.id}">
              Farm Info</a>
            </p>`;

          if (farm?.url !== null && farm?.url !== undefined) {
            elemInfoArea.innerHTML += `<p>
              <img src="fontawesome/globe.svg" alt="key" class="icon"/>
              <a
                data-testid="farm-info-webpage-link"
                href=${farm?.url?.full}
                target="_blank"
                rel="noreferrer"
                title=${farm?.url?.pretty}>
                Webpage <img src="fontawesome/arrow-up-right-from-square.svg" alt="key" class="icon link-arrow"/></a>
              </p>`;
          }

          if (farmDirections !== null && farmDirections !== undefined) {
            elemInfoArea.innerHTML += `<p>
                  <img src="fontawesome/car.svg" alt="key" class="icon"/>
                  <a
                    data-testid="farm-info-directions-link"
                    href=${farmDirections}
                    target="_blank"
                    rel="noreferrer"
                    title="Directions">
                    Directions <img src="fontawesome/arrow-up-right-from-square.svg" alt="key" class="icon link-arrow"/></a>
                  </p>`;
          }

          if (previousFarm.current.id === currentFarm.current.id) {
            // Clicked on same marker again
            hide.current = !hide.current;
          }

          if (hide.current) {
            // Hide
            const elem = window.document.getElementById(`farm-marker-id-${currentFarm.current.id}`);
            elem.classList.remove("highlight");

            const elemInfo = window.document.getElementById(`farm-marker-info-id-${currentFarm.current.id}`);
            elemInfo.classList.add("hidden");
          }
        } catch (error) {
          // "Element no longer visible on map, eg if farm toggled off"
          // TODO prevent this with logic perhaps, eg in <Farms> set this and use
          // const currentFarmInSelection = farms.map((farm) => farm.id).includes(currentFarm.current);
        }
      }

      previousFarm.current = { id: farm.id, marker: markerRef.current };

      markerRef.current.zIndex = farmMarker_zIndex_above;

      map.panTo(offsetCenter(farmPosition, map));
    };

    if (!markerRef.current) return;
    if (isNaN(farm.lat) || isNaN(farm.lng)) return;

    // Bridge the gap btw JSX + HTML/DOM with useEffect

    // Advanced Markers expect DOM node or HTML so translate react.js to HTML/DOM with useEffect
    // children refers to <div …><h2>..</h2>.</div> inside of <FarmMarker..></FarmMarker>

    // Now we can work with the <div ..> as if it was a react component
    // Because every time we update the contents (children) of the Marker component we are re-rendering them with
    // rootRef.current.render(children);

    rootRef.current.render(children);

    markerRef.current.position = { lat: farm.lat, lng: farm.lng };
    markerRef.current.title = `${farm.id}. ${farm.name}`;
    markerRef.current.map = map;

    markerRef.current.addEventListener("gmp-click", setSelectedFarm);

    // Add marker to cluster
    markerClusterer?.addMarker(markerRef.current);

    // on unmount cleanup
    return () => {
      markerClusterer?.removeMarker(markerRef.current);
      markerRef.current.removeEventListener("gmp-click", setSelectedFarm);
      markerRef.current.position = null;
    };
  }, [map, currentFarm, previousFarm, markerClusterer, children, farm]);

  return null;
};

const OriginMarker = ({ map, position }) => {
  const markerRef = useRef(); // Do not need state as we don't need to rerender marker, Ref: https://www.youtube.com/watch?v=s4n_x5B58Dw
  const rootRef = useRef();

  useEffect(() => {
    // Initialise marker
    if (markerRef.current) return;
    if (isNaN(position.lat) || isNaN(position.lng)) return;

    const container = document.createElement("div");
    const attr = document.createAttribute("data-testid");
    attr.value = "origin-marker";
    container.setAttributeNode(attr);

    if (!rootRef.current) {
      rootRef.current = createRoot(container);

      const customPin = new window.google.maps.marker.PinElement({
        // glyph: "", // Empty string to hide the marker
        glyph: container,
        glyphColor: "white",
        background: "#da70d6",
        borderColor: "#FFFFFF",
        scale: 1.5,
      });

      markerRef.current = new window.google.maps.marker.AdvancedMarkerView({
        map: map,
        position: position,
        title: "Current location",
        content: customPin.element,
        zIndex: 2000000, // Should be above all other markers
      });
    }
  }, [map, position]);

  useEffect(() => {
    // Render marker when updated

    if (!markerRef.current) return;
    if (isNaN(position.lat) || isNaN(position.lng)) return;

    // Bridge the gap btw JSX + HTML/DOM
    rootRef.current.render(<FontAwesomeIcon icon={faFaceSmile} size="2x" />);

    markerRef.current.position = position;
    markerRef.current.map = map;
    map.panTo(position);

    // cleanup on unmount
    return () => {
      markerRef.current.position = null;
    };
  }, [map, position]);

  return null;
};

export default MapOfFarms;
