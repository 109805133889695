// external
import { React } from "react";
import { useLoaderData, json } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faCar,
  faEnvelope,
  faGlobe,
  faHouseFlag,
  faKey,
  faLocationDot,
  faPalette,
  faRoad,
  faVenusMars,
} from "@fortawesome/free-solid-svg-icons";

// internal
import { AlpacaDetail } from "../components/AlpacaDetail.js";

const AlpacasDetail = ({ status, alpacas }) => {
  return alpacas.map((alpaca) => <AlpacaDetail status={status} alpaca={alpaca} />);
};

export const farmLoader = async ({ params }) => {
  const response = await fetch(`/api/company/${params.id}`);

  try {
    if (response.status !== 200) {
      throw json({ message: response.statusText }, { status: response.status });
    }

    const data = await response.json();

    if (data.length !== 1) {
      throw json({ message: "Farm object error" }, { status: 404 });
    }

    return data[0];
  } catch (error) {
    console.error(error);
    throw new Error("🧨 farmLoader: Could not load farm");
  }
};

export const Farm = () => {
  const farm = useLoaderData();
  const alpacasActive = farm?.alpacas?.status?.active;
  const alpacasDead = farm?.alpacas?.status?.dead;
  const alpacasExport = farm?.alpacas?.status?.export;
  const farmDirections = farm?.location?.google?.directions_url_href
    ? farm?.location?.google?.directions_url_href
    : null;

  return (
    <>
      <header>
        <h2>{farm?.name}</h2>
      </header>
      <main>
        <article id="farm">
          <section className="overview">
            <div className="box">
              <h3>Farm Overview</h3>
              <p class="inner-box" data-testid="farm-public-private">
                <div class="box-row">
                  <div className="icon">
                    {farm?.public === true ? <FontAwesomeIcon icon={faHouseFlag} /> : <FontAwesomeIcon icon={faKey} />}
                  </div>
                  <div className="text">{farm?.public === true ? "Public Farm" : "Private Farm"}</div>
                </div>
              </p>

              {farm?.url && (
                <address data-testid="farm-address-webpage">
                  <p class="inner-box">
                    <div class="box-row">
                      <div className="icon">
                        <FontAwesomeIcon icon={faGlobe} />
                      </div>
                      <div className="text">
                        <a href={farm?.url?.full} target="_blank" rel="noreferrer" data-testid="farm-webpage-link">
                          {farm?.url?.pretty}{" "}
                          <span className="icon link-arrow">
                            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                          </span>
                        </a>
                      </div>
                    </div>
                  </p>
                </address>
              )}

              <address data-testid="farm-address" class="inner-box">
                <p class="box-row" data-testid="farm-address-city">
                  <div className="icon">{<FontAwesomeIcon icon={faLocationDot} />}</div>
                  <div className="text">City: {farm?.city}</div>
                </p>
                <div class="box-row" data-testid="farm-address-street">
                  <div className="icon">{<FontAwesomeIcon icon={faRoad} />}</div>
                  <div className="text"> Address: {farm?.location?.google?.formatted_address}</div>
                </div>
                <div class="box-row" data-testid="farm-address-email">
                  <div className="icon">{<FontAwesomeIcon icon={faEnvelope} />}</div>
                  <div className="text"> Email: {farm?.email}</div>
                </div>
              </address>

              {farmDirections && (
                <address data-testid="farm-directions">
                  <p class="inner-box">
                    <div class="box-row">
                      <div className="icon">
                        <FontAwesomeIcon icon={faCar} />
                      </div>
                      <div className="text">
                        <a href={farmDirections} target="_blank" rel="noreferrer" data-testid="farm-directions-link">
                          Directions
                          <span className="icon link-arrow">
                            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                          </span>
                        </a>
                      </div>
                    </div>
                  </p>
                </address>
              )}
            </div>
          </section>
          <section className="more">
            <div className="box">
              <h3>Alpaca Overview</h3>
              <p class="inner-box" data-testid="alpaca-count">
                <div class="box-row">
                  <div className="icon">🦙</div>
                  <div className="text">Alpacas: {farm.count?.alpacas?.status?.active}</div>
                </div>
              </p>
              <p class="inner-box" data-testid="alpaca-gender">
                <div class="box-row">
                  <div className="icon">{<FontAwesomeIcon icon={faVenusMars} />}</div>
                  <div className="text">
                    <ul>
                      {farm?.aggregations?.alpacas?.gender?.buckets.map((bucket) => {
                        return (
                          <li key={bucket?.key}>
                            {bucket?.key} - {bucket?.doc_count}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </p>
              <p class="inner-box" data-testid="alpaca-colour">
                <div class="box-row">
                  <div className="icon">{<FontAwesomeIcon icon={faPalette} />}</div>
                  <div className="text">
                    <ul>
                      {farm?.aggregations?.alpacas?.color1?.buckets.map((bucket) => {
                        return (
                          <li key={bucket?.key}>
                            {bucket?.key} - {bucket?.doc_count}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </p>
            </div>

            {alpacasActive?.length === 0 ? null : (
              <div className="box">
                <h3>Alpacas</h3>
                <AlpacasDetail status="active" alpacas={alpacasActive} />
              </div>
            )}

            {alpacasDead?.length === 0 ? null : (
              <div className="box">
                <h3>Alpacas - Dead</h3>
                <AlpacasDetail status="dead" alpacas={alpacasDead} />
              </div>
            )}

            {alpacasExport?.length === 0 ? null : (
              <div className="box">
                <h3>Alpacas - Export</h3>
                <AlpacasDetail status="export" alpacas={alpacasExport} />
              </div>
            )}
          </section>
        </article>
      </main>
    </>
  );
};
