// external
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import reportWebVitals from "./reportWebVitals.js";

// internal
import "./index.css";
import About from "./pages/About.js";
import { Alpaca, alpacaLoader } from "./pages/Alpaca.js";
import App from "./App.js";
import { Farm, farmLoader } from "./pages/Farm.js";
import Search from "./pages/Search.js";
import FindAlpacasOnMap from "./pages/FindAlpacasOnMap.js";
import Error from "./pages/Error.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Error />,
    children: [
      { index: true, element: <FindAlpacasOnMap />, errorElement: <Error /> },
      {
        path: "about",
        element: <About />,
        errorElement: <Error />,
      },
      {
        path: "alpaca/:id",
        element: <Alpaca />,
        errorElement: <Error />,
        loader: alpacaLoader,
      },
      {
        path: "farm/:id",
        element: <Farm />,
        errorElement: <Error />,
        loader: farmLoader,
      },
      {
        path: "search",
        element: <Search />,
        errorElement: <Error />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
