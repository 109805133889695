// external
import { React } from "react";
import { useLoaderData, json } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faKey, faHouseFlag, faLocationDot } from "@fortawesome/free-solid-svg-icons";

// internal
import { AlpacaDetail } from "../components/AlpacaDetail.js";
import { generateImageSource } from "../functions/generateImageSource.js";

export const alpacaLoader = async ({ params }) => {
  const response = await fetch(`/api/animal/${params.id}`);

  try {
    if (response.status !== 200) {
      throw json({ message: response.statusText }, { status: response.status });
    }

    const data = await response.json();

    if (data.length !== 1) {
      throw json({ message: "Alpaca object error" }, { status: 404 });
    }

    return data[0];
  } catch (error) {
    console.error(error);
    throw new Error("🧨 alpacaLoader: Could not load data");
  }
};

export const Alpaca = () => {
  const alpaca = useLoaderData();
  const color = alpaca?.color?.color1?.original;
  const imageSource = generateImageSource(color);

  const statusText = alpaca?.status; // TODO Cleanup status coming from API instead of here

  let status = undefined;

  switch (statusText) {
    case "STATUS_ACTIVE":
      status = "active";
      break;
    case "STATUS_DEAD":
      status = "dead";
      break;
    case "STATUS_EXPORT":
      status = "export";
      break;
    default:
      console.log(`[LOG] No status match for ${status}`);
  }

  return (
    <>
      <header>
        <h2>{alpaca?.alpacaShortName}</h2>
      </header>
      <main>
        <article id="alpaca">
          <section className="overview">
            <div className="box profile-graphic" data-testid="profile-graphic">
              <img
                data-testid="profile-graphic-src"
                className="graphic"
                src={imageSource}
                alt={`Alpaca ${alpaca?.alpacaShortName}, graphic generated by AI`}
              />
            </div>

            <div className="box">
              <h3>Lives at</h3>
              <p class="inner-box" data-testid="farm-name">
                <div class="box-row">
                  <div className="icon">{<FontAwesomeIcon icon={faCircleInfo} />}</div>
                  <div className="text">
                    <a data-testid="farm-name-link" href={`/farm/${alpaca?.companyId}`}>
                      {alpaca?.keeperName}
                    </a>
                  </div>
                </div>
              </p>
              <p class="inner-box" data-testid="farm-public-private">
                <div class="box-row">
                  <div className="icon">
                    {alpaca?.public === true ? (
                      <FontAwesomeIcon icon={faHouseFlag} />
                    ) : (
                      <FontAwesomeIcon icon={faKey} />
                    )}
                  </div>
                  <div className="text">{alpaca?.public === true ? "Public Farm" : "Private Farm"}</div>
                </div>
              </p>

              <address data-testid="farm-address" class="inner-box">
                <p class="box-row" data-testid="farm-address-city">
                  <div className="icon">{<FontAwesomeIcon icon={faLocationDot} />}</div>
                  <div className="text">City: {alpaca?.city}</div>
                </p>
              </address>
            </div>
          </section>
          <section className="more">
            <div className="box">
              <h3>Alpaca Detail</h3>
              <AlpacaDetail status={status} alpaca={alpaca} />
            </div>
          </section>
        </article>
      </main>
    </>
  );
};
