// external
import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceSmile } from "@fortawesome/free-solid-svg-icons";
import { faTractor } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

export const InfoArea = ({ farms, currentFarmInfoArea, originLatLng }) => {
  const list = [];
  farms.forEach((farm) => {
    list.push(
      <li key={farm.id}>
        {farm.name} - {farm.city} - {farm.count?.alpacas?.status?.active} 🦙
      </li>
    );
  });

  return (
    <>
      <section id="map-info">
        <div id="selected-farm" className="box">
          <h4>
            <FontAwesomeIcon icon={faTractor} className="icon" />
            Selected farm
          </h4>

          <address id="address-selected-farm" ref={currentFarmInfoArea} data-testid="address-selected-farm">
            Select: Click map marker / tab. Move between markers: arrows. Show info: spacebar / enter.
          </address>
        </div>
        <div id="origin-location" className="box">
          <h4 data-testid="origin-location-heading">
            <FontAwesomeIcon icon={faFaceSmile} className="icon" />
            Origin latitude, longitude
          </h4>
          <address data-testid="address-origin-latlng">
            {!originLatLng?.lat || !originLatLng?.lng ? "Not selected" : null}
            {originLatLng?.lat ? `lat: ${originLatLng.lat}` : null}
            <br></br>
            {originLatLng?.lng ? `lat: ${originLatLng.lng}` : null}
          </address>
        </div>

        <div id="list-farms" className="box">
          <h4>
            <FontAwesomeIcon icon={faCircleInfo} className="icon" />
            Farms
          </h4>
          <ol data-testid="list-farms">{list}</ol>
        </div>
      </section>
    </>
  );
};
