export const filterPublicPrivateFarms = (farms, facets) => {
  // const keys = Object.keys(searchFacets); // TODO when have cafe etc
  const keys = ["private", "public"];

  return farms.filter((farm) => {
    const match = keys.filter((key) => {
      if (facets[key] === false) return false; // Do not make visible when toggle is OFF
      return farm[key] === facets[key];
    });

    // if (match.length === keys.length) { // TODO for the other facets: cafe, etc
    return match.length > 0;
  });
};
