import React from "react";

function Button({ id, type, className, icon, text, onClick, disabled }) {
  return (
    <button id={id} type={type} className={className} onClick={onClick} disabled={disabled}>
      <span className="icon">{icon}</span>
      <span className="button-text">{text}</span>
    </button>
  );
}

export default Button;
