import React from "react";

function ErrorHandler({ error }) {
  return (
    <div>
      <h2>An error occurred:</h2>
      <p>{error.message}</p>
    </div>
  );
}

export default ErrorHandler;
