import React from "react";

const Error = () => {
  return (
    <>
      <header>
        <h2>Error - page not found</h2>
      </header>
      <main></main>
    </>
  );
};
export default Error;
