// external
import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { Outlet } from "react-router-dom";

// internal
import Nav from "./components/Nav.js";

const TRACKING_ID = "G-3D8PR2VEZS";
ReactGA.default.initialize(TRACKING_ID); // Using ReactGA.default because of https://github.com/codler/react-ga4/issues/23

function App() {
  useEffect(() => {
    ReactGA.default.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: document.title,
    });
  }, []);

  return (
    <>
      <Nav />
      <div id="detail">
        <Outlet />
      </div>
      <footer>
        <Nav />
      </footer>
    </>
  );
}

export default App;
