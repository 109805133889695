export const offsetCenter = (position, map) => {
  /// Ref: https://stackoverflow.com/questions/10656743/how-to-offset-the-center-point-in-google-maps-api-v3

  const offsetX = 0; // move center 0 map width left
  const offsetY = 0.25; // move center one quarter map height down

  var span = map.getBounds().toSpan(); // a latLng - # of deg map spans

  /*

  Example:

  map.getBounds()

  {
    "south": 56.48732992326052,
    "west": 0.12428357171460291,
    "north": 72.31609835819025,
    "east": 35.98365742828541
  }

  map.getBounds().toSpan()

  {
    "lat": 15.828768434929728,
    "lng": 35.85937385657081
  }

  */

  return {
    // To avoid mobile view when the top of the farm marker can be cut off
    lat: position.lat + span.lat() * offsetY,
    lng: position.lng + span.lng() * offsetX,
  };
};

export const getGeoPosition = () => {
  return new Promise((resolve, reject) => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Geolocation_API/Using_the_Geolocation_API
    // Wrap navigator.geolocation browser API in a promise because it returns asynchronously
    // however was written before Promises, therefore it does not return a Promise
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (error) => {
        console.error(error);
        reject(new Error("Could not look up geo position"));
      }
    );
  });
};

export const getFarmsWithinRadius = async (fromLatLng, publicFarms, privateFarms, radius = 500000) => {
  console.log("[LOG] getFarmsWithinRadius - fromLatLng:", fromLatLng);
  // Default radius is 500km, or 500000m as per Elastic request
  const requestURL = `/api/companies/${fromLatLng.lat}/${fromLatLng.lng}/${radius}?public=${publicFarms}&private=${privateFarms}`;

  const response = await fetch(requestURL);

  const result = await response.json();

  return result;
};
