// external
import React, { useState, useEffect, useRef } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";

// internal
import SearchBar from "../components/SearchBar.js";
import MapOfFarms from "../components/MapOfFarms.js";
import ErrorHandler from "../components/Error.js";
import Loading from "../components/Loading.js";
import { getGeoPosition, getFarmsWithinRadius } from "../functions/geoCalculations.js";
import { filterPublicPrivateFarms } from "../functions/filterPrivatePublicFarms.js";
import { InfoArea } from "../components/InfoArea.js";

// If loading google maps not a success show status.  Ref: https://github.com/googlemaps/react-wrapper
const render = (status) => {
  if (status === Status.FAILURE) return <ErrorHandler />;
  return <Loading />;
};

// Map
const center = { lat: 63.9906, lng: 12.3078 };
const zoom = 4;

const FindAlpacasOnMap = () => {
  // Farm
  const [farms, setFarms] = useState(null);
  const [countOfPrivateFarms, setCountOfPrivateFarms] = useState(null);
  const [countOfPublicFarms, setCountOfPublicFarms] = useState(null);
  const currentFarmInfoArea = useRef(null); // For showing farm info when click map marker

  // Search
  const [searchFacets, setSearchFacets] = useState(null);

  // Map Calculations
  const [originLatLng, setOriginLatLng] = useState(null); // for start position in lat, lng
  const [isLocating, setIsLocating] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true); // To control when to set initial bounds on map

  // Initialise data
  const getData = async () => {
    let initialFarms;

    if (window.sessionStorage.getItem("allFarms") === null) {
      const response = await fetch("/api/companies");
      initialFarms = await response.json();
      window.sessionStorage.setItem("allFarms", JSON.stringify(initialFarms));
      console.log("[LOG] Fetched data from server");
    } else {
      initialFarms = JSON.parse(window.sessionStorage.getItem("allFarms"));
      console.log("[LOG] Read data from session storage");
    }

    setCountOfPrivateFarms(initialFarms.filter((farm) => farm.private === true).length);
    setCountOfPublicFarms(initialFarms.filter((farm) => farm.public === true).length);

    // Sync visible farms according to search facets

    let initialFacets;

    if (window.sessionStorage.getItem("searchFacets") === null) {
      initialFacets = {
        private: true,
        public: true,
        currentLocationToggle: false,
        selectLocationToggle: false,
      };

      window.sessionStorage.setItem("searchFacets", JSON.stringify(initialFacets));
    } else {
      initialFacets = Object.assign({}, JSON.parse(window.sessionStorage.getItem("searchFacets")), {
        currentLocationToggle: false, // Reset searched start location when refresh page
        selectLocationToggle: false, // Reset searched start location when refresh page
      });
    }

    const filteredFarms = filterPublicPrivateFarms(initialFarms, initialFacets);

    setFarms(filteredFarms);
    setSearchFacets(initialFacets);
  };

  useEffect(() => {
    getData();
  }, []);

  const updateVisibleFarms = (currentFacets) => {
    setIsInitialLoad(false);

    window.sessionStorage.setItem("searchFacets", JSON.stringify(currentFacets));
    const allFarms = JSON.parse(window.sessionStorage.getItem("allFarms"));

    const filteredFarms = filterPublicPrivateFarms(allFarms, currentFacets);
    setFarms(filteredFarms);
  };

  const setCurrentLocation = async () => {
    try {
      const currentLocation = await getGeoPosition();

      setOriginLatLng(currentLocation);
      return currentLocation;
    } catch (error) {
      console.error(error);
      throw new Error("setCurrentLocation: Could not set current location");
    }
  };

  return (
    <>
      <Wrapper
        apiKey="AIzaSyB4xD7fB993xNwqzBwF0vFpV3Qg_N9UTTc"
        render={render}
        version="beta"
        libraries={["marker", "places"]}>
        <header>
          {!farms ? (
            "Loading..."
          ) : (
            <SearchBar
              countOfPrivateFarms={countOfPrivateFarms}
              countOfPublicFarms={countOfPublicFarms}
              isLocating={isLocating}
              searchFacets={searchFacets}
              originLatLng={originLatLng}
              setOriginLatLng={setOriginLatLng}
              setSearchFacets={setSearchFacets}
              updateVisibleFarms={updateVisibleFarms}
              handleClick_CurrentLocation={async (currentFacets) => {
                if (!currentFacets.currentLocationToggle) {
                  // Toggle is off
                  updateVisibleFarms(currentFacets);
                  return;
                }

                setIsLocating(true);
                setIsInitialLoad(false);

                // Reset map from any previous search
                setOriginLatLng(null);

                // Update map
                const currentLocation = await setCurrentLocation();
                const farmsWithinRadius = await getFarmsWithinRadius(
                  currentLocation,
                  searchFacets.public,
                  searchFacets.private
                );
                setFarms(farmsWithinRadius);

                setIsLocating(false);
              }}
              handleClick_SelectLocation={async () => {
                setIsLocating(true);
                setIsInitialLoad(false);

                const farmsWithinRadius = await getFarmsWithinRadius(
                  originLatLng,
                  searchFacets.public,
                  searchFacets.private
                );
                setFarms(farmsWithinRadius);

                setIsLocating(false);
              }}
            />
          )}
        </header>

        <main>
          {!farms ? (
            "Loading farms..."
          ) : (
            <article id="map-widget">
              <MapOfFarms
                center={center}
                zoom={zoom}
                farms={farms}
                searchFacets={searchFacets}
                originLatLng={originLatLng}
                isInitialLoad={isInitialLoad}
              />
              <InfoArea farms={farms} currentFarmInfoArea={currentFarmInfoArea} originLatLng={originLatLng} />
            </article>
          )}
        </main>
      </Wrapper>
    </>
  );
};

export default FindAlpacasOnMap;
