import React from "react";

function Loading({ type, text, onClick }) {
  return (
    <div>
      <h2>Loading. Please wait.</h2>
    </div>
  );
}

export default Loading;
