import ReactGA from "react-ga4";

// Ref: https://blog.saeloun.com/2022/02/17/how-to-integrate-react-app-with-google-analytics/

const useAnalyticsEventTracker = (category = "Blog category") => {
  const eventTracker = (action = "test action", label = "test label") => {
    ReactGA.default.event({ category, action, label });
  };
  return eventTracker;
};
export default useAnalyticsEventTracker;
