import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapLocationDot, faMagnifyingGlass, faCircleQuestion } from "@fortawesome/free-solid-svg-icons";

const Nav = () => {
  return (
    <>
      <nav>
        <div id="nav-branding">
          <Link to="/">
            <img className="logo" src="/alpaca.life.logo.png" alt="Alpaca logo" />
          </Link>
        </div>
        <div id="nav-menu">
          <Link to="/">
            <span className="icon">
              <FontAwesomeIcon icon={faMapLocationDot} size="2x" />
            </span>
            <span className="hidden-mobile">Find Alpacas on Map</span>
          </Link>

          <Link to="/search">
            <span className="icon">
              <FontAwesomeIcon icon={faMagnifyingGlass} size="2x" />
            </span>
            <span className="hidden-mobile">Search</span>
          </Link>

          <Link to="/about">
            <span className="icon">
              <FontAwesomeIcon icon={faCircleQuestion} size="2x" />
            </span>
            <span className="hidden-mobile">About</span>
          </Link>
        </div>
      </nav>
    </>
  );
};

export default Nav;
